<template>
  <div class="logout-contain">
    <NavBar :title="$t('login.information')" :backColor="'#ff0000'"> </NavBar>
    <div class="logout-main">
      <van-cell-group v-for="(item, index) in LogUesrinfo" :key="index">
        <van-cell :title="$t(i['name'])" v-for="(i, idx) in item" :key="i['id']" :value="index === 2 ? value[idx] : ''"
          @click="goPage(i['topath'], i['id'])" class="current">
          <van-icon slot="right-icon" name="arrow" />
        </van-cell>
      </van-cell-group>
      <van-button round block type="info" class="logot-btn" @click="logout"
        :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }">{{ $t('login.LogOut') }}</van-button>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/Navbar.vue'
export default {
  name: 'Logout',
  components: {
    NavBar,
  },
  data() {
    return {
      LogUesrinfo: [
        [{ id: '0', name: 'login.loginPassword', topath: '/editpasword' }],
        [{ id: '2', name: 'login.myBankCard', topath: '/bank' }],
      ],
      value: ['Current version 5.2', '34.26 MB'],
    }
  },
  mounted() { },
  created() { },
  methods: {
    logout() {
      this.$dialog.confirm({
        title: this.$t('login.LogOut'),
        message: this.$t('login.doYouWantToLogOut'),
        cancelButtonText: this.$t('common.cancel'),
        confirmButtonText: this.$t('common.confirm'),
        confirmButtonColor: '#00aa88',
      })
        .then(() => {
          this.$store.commit('delteUer')
          this.$router.push('/login')
          window.localStorage.clear()
        })
        .catch(() => {
        })
    },
    goPage(topath, id) {
      if (topath) {
        this.$router.push(topath)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.logout-contain {
  position: relative;

  .van-cell {
    height: 100px;
    padding: 30px 20px 30px 24px;
  }

  .logout-main {
    position: absolute;
    top: 118px;
    left: 0;
    right: 0;
  }

  .van-cell-group {
    margin-top: 18px;
  }

  .van-icon {
    margin-top: 8px;
  }

  /deep/ .van-cell__title {
    font-size: 29px;
    color: #666;
  }

  /deep/ .van-cell__value {
    font-size: 12px;
    color: #d2d2d2;
  }

  .logot-btn {
    width: 616px;
    height: 90px;
    background-color: #ff0000;
    color: #fff;
    border: 0;
    font-size: 40px;
    font-family: 'PingFang-Medium';
    margin: 60px auto;
  }

  .current {
    span {
      font-size: 28px;
    }
  }
}
</style>
